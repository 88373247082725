import { Env } from '@ph-core/enums';
import { Environment } from '@ph-env/environment.model';
import { environment as baseGMUSEnvironment } from '@ph-env/gmus/environment.gmus.base';

export const environment: Environment = {
  ...baseGMUSEnvironment,
  production: true,
  type: Env.UAT,
  apiUrl: 'https://uatbetagmprotectioncom.sgdavinci.com',
  okta: {
    SSOCredentials: {
      issuer: 'https://safeguard-uat.oktapreview.com/oauth2/aus2oug9jmJaurlRr1d7',
      clientId: '0oa40npx9fFHphufe1d7',
      idps: [
        {
          idpId: '0oa3pojmkvzzAr9TY1d7',
          buttonLabelTranslationKey: 'loginPage.loginForm.loginUsingOkta',
        },
      ],
    },
  },
  cancellationUrl: 'https://uat-gmcancellationui.safe-guardproducts.com/cancellation-portal/#/carbravous',
  features: {
    ...baseGMUSEnvironment.features,
  },
};
