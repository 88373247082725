import { environment } from '@ph-env/environment';

import { ProductFilter } from '@ph-model/filters/product-filter';
import { ProductFilters } from '@ph-model/filters/product-filters';
import { ProductItem } from '@ph-model/response';

export function filterProducts(filters: ProductFilters, productItems: ProductItem[]): ProductItem[] {
  let filteredProducts = productItems;
  const enabledFilters = environment.features.productFilters;
  for (let i = 0; i < enabledFilters.length; i++) {
    if (enabledFilters[i] !== 'vspSurcharge' && enabledFilters[i] !== 'twSurcharge') {
      filteredProducts = _filterBy(enabledFilters[i], filters, filteredProducts);
    }
    if (i + 1 < enabledFilters.length) {
      if (enabledFilters[i + 1] !== 'vspSurcharge' && enabledFilters[i + 1] !== 'twSurcharge') {
        _hideInconsequentialFilters(enabledFilters[i + 1], filters, filteredProducts);
      }
    }
  }

  return filteredProducts;
}

function _match(property: keyof ProductFilters, product: ProductItem, filter: ProductFilter) {
  switch (property) {
    case 'product':
      return (
        filter.name === product.productDescription.enUsProductDescription ||
        filter.name === product.productDescription.frCaProductDescription
      );
    case 'plan':
      return filter.name === product.planName.enUsPlanName || filter.name === product.planName.frCaPlanName;
    case 'coverage':
      return filter.name === product.coverageDescription;
    case 'deductible':
      return filter.name === product.deductible + (product.disappearingDeductible ? '(D)' : '');
    case 'term':
      return filter.name === product.maxTerm;
    case 'mileage':
      return filter.name === product.termMileage;
    case 'vspSurcharge':
    case 'twSurcharge':
      return true;
    default:
      console.warn(`Unrecognized category key (${property}) passed to _match function`);
  }
}

function _filterBy(
  property: keyof ProductFilters,
  filters: ProductFilters,
  productsToFilter: ProductItem[]
): ProductItem[] {
  const selectedFilters = filters[property].filter((filter) => filter.selected);
  if (!selectedFilters.length) {
    return productsToFilter;
  }

  return productsToFilter.filter((product) => {
    return selectedFilters.find((filter) => _match(property, product, filter));
  });
}

function _hideInconsequentialFilters(property: keyof ProductFilters, filters: ProductFilters, products: ProductItem[]) {
  if (property !== 'vspSurcharge' && property !== 'twSurcharge') {
    filters[property].forEach((filter) => {
      filter.display = filter.selected || !!products.find((product) => _match(property, product, filter));
    });
  }
}
