import { DefaultLienholderEnum, FinanceType, ProductViewType, Role, ShoppingFlowType, Vendor } from '@ph-core/enums';
import { Country } from '@ph-model/country-code';
import { DistanceType } from '@ph-model/distance-type';
import { ShoppingStepsUrls } from '@ph-shared/modules/shopping-stepper';
import { base } from '@ph-env/environment.base';
import { Environment } from '@ph-env/environment.model';
import { cancellationUrl } from '@ph-env/modifiers/cancellation-url';
import { enabledSSO } from '@ph-env/modifiers/enabled-sso';
import { SupportedLanguageCodes } from '@ph-static-data/languages';

export const environment: Omit<Environment, 'apiUrl'> = {
  ...base,
  prismicUid: 'gm',
  prismicTypeId: 'my.gm.uid',
  languageCode: SupportedLanguageCodes.English,
  application: 'gmprotectioncom',
  vendorName: Vendor.GM,
  distanceType: DistanceType.MILE,
  countryCode: Country.USA,
  showTooltipsForSaleDate: true,
  showTooltipsForInServiceDate: true,
  productsSort: {
    ...base.productsSort,
    startup: {
      property: 'product',
      ascending: true,
    },
  },
  ...enabledSSO,
  ...cancellationUrl('https://qa-cancellationui.safe-guardproducts.com/cancellation-portal/#/carbravous'),
  features: {
    ...base.features,
    contractHistory: { displayUserName: true },
    defaultLienholder: DefaultLienholderEnum.GM,
    defaultProductVewType: ProductViewType.Dealer,
    hidePrintPdfButton: false,
    isTokenRequiredForReports: true,
    isNewTokenAfterEmulation: true,
    logoutMode: 'soft',
    noDefaultValuesForRadioButtons: true,
    restrictFutureSaleDateContracts: true,
    showCartSummary: false,
    showMarkup: false,
    showRegisteredContractsControls: false,
    showSaveQuoteOnCartSummaryStep: false,
    showSaveQuoteOnCreateContractStep: true,
    supportAncillaryFlow: true,
    supportCartValidation: true,
    supportChargerFlow: true,
    supportCreditInsuranceFlow: false,
    supportCustomerFormAPR: true,
    supportDOB: false,
    supportDot: true,
    supportGrossCapCost: false,
    supportManualVinDecoding: false,
    supportProvidedLienholder: true,
    supportProvince: false,
    supportTheftProtection: true,
    suppressDealerCost: true,
    transactionTypes: [FinanceType.Cash, FinanceType.Finance, FinanceType.Lease],
    useBACID: true,
    useNewRatesApi: true,
    useNewSaveContractApi: true,
    useQuoteOnlyEndpoint: true,
  },
  shoppingFlow: {
    steps: [
      {
        title: 'shoppingStepper.steps.dealInfo.title',
        url: ShoppingStepsUrls.DealInformation,
        paths: [
          {
            name: ShoppingFlowType.Ancillary,
            steps: [
              {
                title: 'shoppingStepper.steps.productSelection.title',
                url: ShoppingStepsUrls.AncillaryProductSelection,
              },
            ],
          },
          {
            name: ShoppingFlowType.PowerUp,
            steps: [],
          },
        ],
        currentPath: ShoppingFlowType.PowerUp,
      },
      {
        title: 'shoppingStepper.steps.cartSummary.title',
        url: ShoppingStepsUrls.CartSummary,
      },
      {
        title: 'shoppingStepper.steps.customerInfo.title',
        url: ShoppingStepsUrls.CustomerInformation,
      },
      {
        title: 'shoppingStepper.steps.createContract.title',
        url: ShoppingStepsUrls.CreateContract,
      },
    ],
  },
  historyTabReadOnlyRoles: [Role.FIManager, Role.Maintenance, Role.SalesRep, Role.CorporateSales],
  registerTabReadOnlyRoles: [Role.FIManager, Role.Maintenance, Role.SalesRep, Role.CorporateSales],
  shoppingFlowReadOnlyRoles: [Role.CorporateSales],
};
