import { CurrencyPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, inject, input, InputSignal } from '@angular/core';

import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { PhNotification } from '@ph-core/services/notification';
import { IconComponent, PhNotificationType } from '@ph-shared/components';
import { ContractHistoryDirective } from '@ph-shared/directives';
import { EconContractNumberPipe } from '@ph-shared/pipes';

import { HintDirective } from '../hint/hint.directive';
import { StatusChipComponent } from '../status-chip/status-chip.component';
import { getProductCardSpate, getStatusChipLevel } from '../status-chip/status-chip.helpers';
import { PhStatusChipCategory, PhStatusChipType, StatusChipData } from '../status-chip/status-chip.interface';

@Component({
  selector: 'ph-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ContractHistoryDirective,
    CurrencyPipe,
    EconContractNumberPipe,
    HintDirective,
    StatusChipComponent,
    TranslateModule,
    IconComponent,
  ],
})
export class ProductCardComponent {
  vin: InputSignal<string> = input.required();
  vehicleInfo: InputSignal<string> = input.required();
  title: InputSignal<string> = input.required();
  product: InputSignal<string> = input.required();
  plan: InputSignal<string> = input('');
  purchased: InputSignal<string> = input('');
  modified: InputSignal<string> = input('');
  customer: InputSignal<number> = input(0);
  dealer: InputSignal<number> = input(0);
  termMonth: InputSignal<number> = input(0);
  claimNumber: InputSignal<string> = input('');
  dateOfLoss: InputSignal<string> = input('');
  RONumber: InputSignal<string> = input('');
  statuses: InputSignal<StatusChipData[]> = input([]);
  contractNumber: InputSignal<string> = input('');
  showEconContractNumber: InputSignal<boolean> = input(false);

  @HostBinding('class') get colorClass(): { [key: string]: boolean } {
    const type: PhStatusChipType = getProductCardSpate(this.statuses().map((status) => status.type));

    return { [getStatusChipLevel(type)]: true };
  }

  PhStatusChipCategory: typeof PhStatusChipCategory = PhStatusChipCategory;

  private notification: PhNotification = inject(PhNotification);
  private translate: TranslateService = inject(TranslateService);

  statusIndex(index: number): number {
    return index;
  }

  copyToClipboard(vin: string): void {
    void navigator.clipboard.writeText(vin.trim());

    this.notification.display({
      value: {
        message: `${vin.trim()} ${this.translate.instant('tooltips.vinClipboardCopy.notificationMessage')}`,
        title: this.translate.instant('tooltips.vinClipboardCopy.notificationTitle'),
      },
      type: PhNotificationType.info,
      duration: 2000,
    });
  }
}
