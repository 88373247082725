<img class="logo-img" [src]="navbarLogo()" alt="Safe-guard-products" />

<div class="header-block">
  <h1>{{ 'shoppingPage.products.labels.quote' | translate }}</h1>
  <p>{{ 'shoppingPage.products.labels.printed' | translate }}: {{ date | date: 'shortDate' }}</p>
</div>

<div class="information-block">
  <div class="information-row heading">
    <p>{{ isChargerFlow ? 'Charger Information' : ('shoppingPage.products.labels.vehicleInformation' | translate) }}</p>
    <p>{{ 'shoppingPage.products.labels.dealerInformation' | translate }}</p>
  </div>
  <div class="information-row">
    @if ({ vehicle: vehicle$ | async }; as obs) {
      <div class="information-cell">
        <p>{{ vinLabel }}: {{ obs.vehicle.vin }}</p>
        <p>{{ 'shoppingPage.products.labels.make' | translate }}: {{ obs.vehicle.make }}</p>
        <p>{{ 'shoppingPage.products.labels.model' | translate }}: {{ obs.vehicle.model }}</p>
        <p>{{ 'shoppingPage.products.labels.year' | translate }}: {{ obs.vehicle.year }}</p>
      </div>
      <div class="information-cell">
        <p>{{ user().fullName }}</p>
        <p>{{ user().name }}</p>
        <p>{{ user().dealerInfo?.dealerAddress1 }}</p>
        <p>
          {{ user().dealerInfo?.dealerCity }}, {{ user().dealerInfo?.dealerState }}
          {{ user().dealerInfo?.dealerZipCode }}
        </p>
        <p>{{ user().dealerInfo?.dealerPhone }}</p>
      </div>
    }
  </div>
</div>

<div class="invoice-block">
  @if (!creditIns()?.insResponse?.generalInfo?.applicationId) {
    <div class="invoice-row heading">
      <span>Product</span>
      <span>Description</span>
      <span>Customer Price</span>
    </div>
  }
  @for (item of cart(); track item; let i = $index) {
    @if (
      i !== 0 &&
      (i === amountOfContractOnFirstPage || (i - amountOfContractOnFirstPage) % amountOfContractOnRestPages === 0)
    ) {
      <div style="page-break-after: always"></div>
    }
    <div class="invoice-row">
      <span>{{
        language === 'fr-CA'
          ? item.product.productDescription.frCaProductDescription
          : item.product.productDescription.enUsProductDescription
      }}</span>
      <span
        >{{ language === 'fr-CA' ? item.product.planName.frCaPlanName : item.product.planName.enUsPlanName }} -
        {{ item.product.customerTerm }} MO/${{ item.product.deductible }},
        @if (showTermInQuotePdf) {
          <span>{{ 'shoppingPage.products.labels.termMiles' | translate }} - {{ item.product.termMileage }}</span>
        }
      </span>
      <span>${{ item.product.customerCost }}</span>
    </div>
  }

  @if (creditIns()?.insResponse?.generalInfo?.applicationId) {
    <span>{{ 'shoppingPage.products.labels.quoteId' | translate }}: {{creditIns()?.insResponse?.generalInfo?.applicationId }}</span>
  }
</div>

<div class="coverage-block">
  @for (coverage of coverage$ | async; track coverage) {
    <div class="coverage-row heading">
      <span>{{ 'shoppingPage.products.labels.coveredBorrower' | translate }}</span>
      <span>{{ 'shoppingPage.products.labels.description' | translate }}</span>
      <span>{{ 'shoppingPage.products.labels.premium' | translate }}</span>
      <span>{{ 'shoppingPage.products.labels.tax' | translate }}</span>
    </div>
    <div class="coverage-row">
      <span>{{ coverage.borrCovered }}</span>
      <span
        >{{ coverage.coverageDescription | translate }} - {{ 'shoppingPage.products.top-filter.term' | translate }}
        {{ coverage.insTerm }}</span
      >
      <span>${{ coverage.premium }}</span>
      <span>${{ coverage.tax }}</span>
    </div>
  }
</div>

@if (!creditIns()?.insResponse?.generalInfo?.applicationId) {
  <p class="note">{{ 'shoppingPage.products.labels.note' | translate }}</p>
}
