import { FormControl } from '@angular/forms';

import { environment } from '@ph-env/environment';

export function VinLimitValidator(control: FormControl): null | { error: boolean; errorMessage: string } {
  return environment.nonAuto ? VinLimitValidatorNonAuto(control) : VinLimitValidatorAuto(control);
}

export function VinMinMaxLengthValidator(control: FormControl): null | { error: boolean; errorMessage: string } {
  const value = control.value;
  if (value && (value.length < 7 || value.length > 17)) {
    return {
      error: true,
      errorMessage: value.length < 7 ? 'errors.vinMinLength' : 'errors.vinMaxLength',
    };
  }

  return null;
}

function VinLimitValidatorAuto(control: FormControl): null | { error: boolean; errorMessage: string } {
  const value = control.value;
  if (value && value.length !== 17) {
    return {
      error: true,
      errorMessage: 'errors.vin17',
    };
  }

  return null;
}

function VinLimitValidatorNonAuto(control: FormControl): null | { error: boolean; errorMessage: string } {
  const value = control.value;
  if (value && (value.length < 4 || value.length > 17)) {
    return {
      error: true,
      errorMessage: 'errors.vin417',
    };
  }

  return null;
}

export function VinInvalidCharValidator(control: FormControl): null | { required: boolean; errorMessage: string } {
  const value = control.value;
  const letterNumber = /^[0-9a-hj-npr-zA-HJ-NPR-Z]+$/;
  if (value && !value.match(letterNumber)) {
    return {
      required: true,
      errorMessage: 'errors.vinAlpha',
    };
  }

  return null;
}

export function engineCCValidator(control: FormControl): null | { required: boolean; errorMessage: string } {
  const value = control.value;
  const letterNumber = /^[0-9]+$/;
  if (value && !value.match(letterNumber)) {
    return {
      required: true,
      errorMessage: 'errors.engineCC',
    };
  }

  return null;
}

export function characterValidator(control: FormControl): null | { required: boolean; errorMessage: string } {
  const value = control.value;
  const letterNumber = /^[a-zA-Z]+$/;
  if (value && !value.match(letterNumber)) {
    return {
      required: true,
      errorMessage: 'errors.model',
    };
  }

  return null;
}

export function TinLimitValidator(control: FormControl): null | { error: boolean; errorMessage: string } {
  const value = control.value;
  if (value == '') {
    return null;
  }
  if (value && value.length > 3 && value.length < 7) {
    return null;
  } else {
    return {
      error: true,
      errorMessage: 'errors.tin6',
    };
  }
}

export function TinInvalidCharValidator(control: FormControl): null | { required: boolean; errorMessage: string } {
  const value = control.value;
  const letterNumber = /^[0-9a-zA-Z]+$/;
  if (value && !value.match(letterNumber)) {
    return {
      required: true,
      errorMessage: 'errors.tinAlpha',
    };
  }

  return null;
}

export function PidLimitValidator(control: FormControl): null | { error: boolean; errorMessage: string } {
  const value = control.value;
  if (value && value.length !== 17) {
    return {
      error: true,
      errorMessage: 'errors.pid17',
    };
  }

  return null;
}

export function engineCCLimitValidator(control: FormControl): null | { required: boolean; errorMessage: string } {
  const value = control.value;
  if (value && value.length > 4) {
    return {
      required: true,
      errorMessage: 'errors.engineCCLimit',
    };
  }

  return null;
}

export function PidInvalidCharValidator(control: FormControl): null | { required: boolean; errorMessage: string } {
  const value = control.value;
  const letterNumber = /^[0-9a-zA-Z]+$/;
  if (value && !value.match(letterNumber)) {
    return {
      required: true,
      errorMessage: 'errors.pidAlpha',
    };
  }

  return null;
}

export function HinLimitValidator(control: FormControl): null | { error: boolean; errorMessage: string } {
  const value = control.value;
  if (value && value.length !== 12) {
    return {
      error: true,
      errorMessage: 'errors.hin12',
    };
  }

  return null;
}

export function HinInvalidCharValidator(control: FormControl): null | { required: boolean; errorMessage: string } {
  const value = control.value;
  const letterNumber = /^[0-9a-zA-Z]+$/;
  if (value && !value.match(letterNumber)) {
    return {
      required: true,
      errorMessage: 'errors.hinAlpha',
    };
  }

  return null;
}

export function aprLimitValidator(control: FormControl): null | { error: boolean; errorMessage: string } {
  const value = control.value;
  if (!value) {
    return {
      error: true,
      errorMessage: 'errors.aprLimit',
    };
  }

  return null;
}

export function unitIdValidator(control: FormControl): null | { error: boolean; errorMessage: string } {
  const value = control.value;
  if (value == '') {
    return null;
  }
  if (value && value.length > 3 && value.length < 18) {
    return null;
  } else {
    return {
      error: true,
      errorMessage: 'errors.vin17',
    };
  }
}

export function unitIdCharValidator(control: FormControl): null | { required: boolean; errorMessage: string } {
  const value = control.value;
  const letterNumber = /^[0-9a-zA-Z]+$/;
  if (value && !value.match(letterNumber)) {
    return {
      required: true,
      errorMessage: 'errors.vinAlpha',
    };
  }

  return null;
}

export function charactersValidator(control: FormControl): null | { required: boolean; errorMessage: string } {
  const value = control.value;
  const letterNumber = /^[a-zA-Z]+$/;
  if (value && value.value !== '' && !value.match(letterNumber)) {
    return {
      required: true,
      errorMessage: 'errors.characters',
    };
  }

  return null;
}
