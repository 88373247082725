import { GetRatesRequest } from '@ph-model/request/rates.request';
import { GetProductsResponse, ProductItem, RatesResponse } from '@ph-model/response';
import { AvailableOptions, Products, SurchargeOption } from '@ph-model/response/interface';
import { Rate, Surcharge } from '@ph-model/response/interface';

export function mapRatesToGetProductsResponse(
  res: RatesResponse,
  req: GetRatesRequest,
  adjustedMappingsProductCodes: string[]
): GetProductsResponse {
  return {
    availableOptions: mapToAvailableOptions(res, req, adjustedMappingsProductCodes),
    standardResponse: {
      status: res.status,
      responseCode: res.responseCode,
      responseDescription: res.responseDescription,
    },
  };
}

function mapToAvailableOptions(
  res: RatesResponse,
  req: GetRatesRequest,
  adjustedMappingsProductCodes: string[]
): AvailableOptions {
  return {
    dealer: res.data.dealerDetails.dealerCode,
    vin: res.data.vehicleDetails.vin,
    vehicleMake: res.data.vehicleDetails.make,
    vehicleModel: res.data.vehicleDetails.model,
    vehicleYear: res.data.vehicleDetails.modelYear.toString(),
    financeAmount: req.financedAmount.toString(),
    saleDate: req.saleDate.replace(/-/g, '/'),
    inServiceDate: req.inServiceDate.replace(/-/g, '/'),
    isAfterSale: req.isAfterSale.toString(),
    vehiclePurchaseDate: req.vehiclePurchaseDate.replace(/-/g, '/'),
    vehicleTrim: null,
    financeType: req.dealType,
    vehicleCondition: req.vehicleCondition,
    vehicleCost: req.vehiclePurchasePrice.toString(),
    msrp: req.msrp.toString(),
    programName: res.data.dealerDetails.programName,
    products: mapToProducts(res, adjustedMappingsProductCodes),
    vehicleMSRP: req.msrp.toString(),
  };
}

function mapToProducts(resp: RatesResponse, adjustedMappingsProductCodes: string[]): Products {
  const dealer = resp.data.dealerDetails.dealerCode;

  return {
    productItem: resp.data.productRateDetails.map((prod: Rate) =>
      mapToProduct(prod, dealer, adjustedMappingsProductCodes)
    ),
  };
}

function mapToSurcharges(surcharges: Surcharge[]): SurchargeOption[] {
  return surcharges.map((surcharge: Surcharge) => ({
    label: {
      enUsLabel: surcharge.surchargeName,
      frCaLabel: surcharge.surchargeName,
    },
    key: surcharge.surchargeCode,
    surchargeAmount: surcharge.amount.toString(),
    editable: 'true',
    mandatory: surcharge.mandatory.toString(),
    type: surcharge.surchargeCode,
    description: surcharge.surchargeDescription,
    category: surcharge.category,
  }));
}

function mapToProduct(product: Rate, dealer: string, adjustedMappingsProductCodes: string[]): ProductItem {
  const isAdjustedMapping: boolean = adjustedMappingsProductCodes.includes(product.productCode);

  return {
    dealer,
    vendor: null,
    productType: product.productType,
    productCode: product.productCode,
    productCategory: null,
    productDescription: {
      enUsProductDescription: isAdjustedMapping ? product.productDescription : product.coverageDescription,
      frCaProductDescription: '',
    },
    planCode: product.planID?.toString(), // ??
    planName: {
      enUsPlanName: product.coverageDescription, // product.planName
      frCaPlanName: '',
    },
    planSKU: product.productSku,
    usageType: null,
    minTerm: product.termFrom?.toString(),
    maxTerm: product.termTo?.toString(),
    isBundled: false,
    isDiscounted: false,
    termMileage: product.termMileage?.toString(),
    termInput: null,
    deductible: product.deductible?.toString(),
    disappearingDeductible: product.disappearingDeductible,
    vehicleClass: null,
    financeAmountFrom: product.vehicleCostMin?.toString(),
    financeAmountTo: product.vehicleCostMax?.toString(),
    msrpTo: null,
    msrpFrom: null,
    vehicleSellingPriceFrom: null,
    vehicleSellingPriceTo: null,
    grossCapCostFrom: null,
    grossCapCostTo: null,
    dealerCost: product.dealerCost,
    regulatedRate: product.regulatedRate,
    retailRateType: product.regulatedRate,
    retailRate: product.retailCost,
    maxCost: product.maxCost,
    surchargeOptions: { surchargeOption: mapToSurcharges(product.surcharges) }, // product.surcharges
    startingInterval: product.startingInterval?.toString(),
    formNumber: product.formNumber,
    formDescription: isAdjustedMapping
      ? product.formDescription || product.coverageDescription
      : product.formDescription,
    vehicleClassing: '',
    coverageCode: product.coverageCode,
    coverageDescription: product.coverageDescription,
    coverageOptions: { coverageOption: [] }, // PowerUp Products resp has other CoverageOption structure
    minDollarAmount: null,
    maxDollarAmount: null,
    paymentType: product.paymentType,
    startingIntervalName: product.startingIntervalName?.toString(),
    minOdometer: product.odometerMin?.toString(),
    maxOdometer: product.odometerMax?.toString(),
    programType: product.programType,
    planType: '',
    childProducts: null, // ChildProducts
    taxes: null, // ? Tax
    externalProductReferenceCode: null,
    vehicleCondition: product.vehicleCondition,
    riskType: product.riskType,
    programAgentCode: product.programAgentCode,
  };
}
