<div class="product-card-header">
  <h3 class="product-card-title">
    <ng-content select="[titleTooltip]" />
    {{ title() }}
  </h3>
  <ng-content select="[header]" />
</div>
<div class="product-card-content">
  <div class="product-descriptions" [class.wide-column]="showEconContractNumber()">
    <div class="product-description-row">
      <div class="product-card-metric-label">{{ 'historyPage.filterPage.grid.vehicle' | translate }}</div>
      <div class="product-card-metric-value">
        <span class="vin-value">{{ vin() }}
          <button
            (click)="copyToClipboard(vin())"
            [phHint]="'tooltips.vinClipboardCopy.tooltip' | translate"
            class="copy-button"
          >
          <ph-icon name="copy" width="18px" height="18px" />
        </button>
        </span> - {{ vehicleInfo() }}
      </div>
    </div>
    <div class="product-description-row">
      <div class="product-card-metric-label">{{ 'historyPage.filterPage.grid.product' | translate }}</div>
      <div class="product-card-metric-value">{{ product() }}</div>
    </div>
    @if (plan()) {
      <div class="product-description-row">
        <div class="product-card-metric-label">{{ 'historyPage.filterPage.grid.plan' | translate }}</div>
        <div class="product-card-metric-value">{{ plan() }}</div>
      </div>
    }
    @if (showEconContractNumber() && contractNumber()) {
      <div class="product-description-row">
        <div class="product-card-metric-label">{{ 'historyPage.filterPage.grid.referenceNo' | translate }}</div>
        <div class="product-card-metric-value">{{ contractNumber() | econContractNumber }}</div>
      </div>
    }
  </div>
  <div class="product-parameters">
    @if (purchased()) {
      <span class="product-card-metric-label">{{ 'historyPage.filterPage.grid.purchased' | translate }}</span>
    }
    @if (purchased()) {
      <span class="product-card-metric-value">{{ purchased() }}</span>
    }
    @if (modified()) {
      <span class="product-card-metric-label">{{ 'historyPage.filterPage.grid.modified' | translate }}</span>
    }
    @if (modified()) {
      <span class="product-card-metric-value">{{ modified() }}</span>
    }
    @if (customer()) {
      <span class="product-card-metric-label">{{ 'historyPage.filterPage.grid.customer' | translate }}</span>
    }
    @if (customer()) {
      <span class="product-card-metric-value">{{ customer() | currency: 'USD' : 'symbol' : '1.0-2' }}</span>
    }
    @if (dealer()) {
      <span class="product-card-metric-label">{{ 'historyPage.filterPage.grid.dealer' | translate }}</span>
    }
    @if (dealer()) {
      <span class="product-card-metric-value">{{ dealer() | currency: 'USD' : 'symbol' : '1.0-2' }}</span>
    }
    @if (termMonth()) {
      <span class="product-card-metric-label">{{ 'shoppingPage.products.top-filter.term' | translate }}</span>
    }
    @if (termMonth()) {
      <span class="product-card-metric-value">{{ termMonth() }}</span>
    }
    @if (claimNumber()) {
      <span class="product-card-metric-label">{{ 'claimsPage.filterPage.grid.claimNumber' | translate }}</span>
    }
    @if (claimNumber()) {
      <span class="product-card-metric-value">{{ claimNumber() }}</span>
    }
    @if (dateOfLoss()) {
      <span class="product-card-metric-label">{{ 'claimsPage.filterPage.grid.dateOfLoss' | translate }}</span>
    }
    @if (dateOfLoss()) {
      <span class="product-card-metric-value">{{ dateOfLoss() }}</span>
    }
    @if (RONumber()) {
      <span class="product-card-metric-label">{{ 'claimsPage.filterPage.grid.roNumber' | translate }}</span>
    }
    @if (RONumber()) {
      <span class="product-card-metric-value">{{ RONumber() }}</span>
    }
  </div>
</div>

<div class="product-card-footer">
  <div class="status-chip-block">
    @for (status of statuses(); track statusIndex) {
      @switch (status.category) {
        @case (PhStatusChipCategory.contractLifecycle) {
          <ph-status-chip
            [type]="status.type"
            [label]="status.label"
            [phHint]="'historyPage.filterPage.grid.contractHistoryCTA' | translate"
            phContractHistoryPopup
            class="clickable-chip"
            [contractId]="contractNumber()"
          />
        }
        @default {
          <ph-status-chip [type]="status.type" [label]="status.label" [phHint]="status.tooltip" />
        }
      }
    }
  </div>

  <ng-content select="[footer]" />
</div>
