import { ShoppingStepsUrls } from '@ph-shared/modules/shopping-stepper';
import { Environment } from '@ph-env/environment.model';
import { Country } from '@ph-model/country-code';
import { DistanceType } from '@ph-model/distance-type';
import {
  DefaultLienholderEnum,
  Env,
  FinanceType,
  ProductViewType,
  Role,
  ShoppingFlowType,
  VehicleConditionType,
  Vendor,
} from '@ph-core/enums';

export const base: Omit<Environment, 'application' | 'apiUrl' | 'languageCode'> = {
  production: false,
  version: require('../../package.json').version,
  vendorName: Vendor.Default,
  type: Env.Default,
  apiMock: {
    enabled: false,
    showWarnings: false,
    endpointsBehavior: {},
  },
  tokenExpirationCheck: true,
  auth: {
    sso: false,
  },
  nonAuto: false,
  prismicAccessToken:
    'MC5ZV1FtV1JJQUFDa0EwX0hS.77-977-977-9VEvvv70FGy_vv71D77-977-9Uu-_vQXvv73vv73vv73vv71t77-977-977-977-9KiTvv70o77-977-977-9',
  prismicUid: 'ep-ca',
  prismicTypeId: 'my.ep-ca.uid',
  prismicProductImagesId: 'product-images',
  cancellationUrlConfig: {
    source: 'SERVER',
    params: [],
  },
  cancellationUrl: undefined,
  achPaymentsUrl: undefined,
  claimsPPMPortalUrl: undefined,
  claimsPortalLayout: undefined,
  okta: {
    SSOCredentials: {
      issuer: '',
      clientId: '',
      idps: [],
    },
  },
  countryCode: Country.CAN,
  distanceType: DistanceType.KILOMETER,
  productsSort: {
    startup: {
      property: 'product',
      ascending: true,
    },
    sortingPropertiesOrder: ['product', 'plan', 'term', 'cost'],
  },
  datepickerDateRanges: {
    firstPaymentDate: [36500, 36500],
    purchaseDate: [36500, 36500],
    inServiceDate: [36500, 0],
    saleDate: [30, 15],
    chargerPurchaseDate: [30, 15],
  },
  features: {
    contractHistory: { displayUserName: false },
    customerCostAtLeastOneDollarToProceed: false,
    customerEmailIsRequired: true,
    defaultLienholder: DefaultLienholderEnum.NoDefault,
    defaultProductVewType: ProductViewType.Customer,
    defaultTransactionType: FinanceType.Cash,
    dynamicApplicationRedirects: [],
    financeAmountIsRequired: true,
    hasRequestAccountBtnOnLoginPage: true,
    hidePrintPdfButton: false,
    isNewTokenAfterEmulation: false,
    isTokenRequiredForReports: false,
    logoutMode: 'full',
    noDefaultValuesForRadioButtons: false,
    productFilters: ['product', 'deductible', 'term', 'mileage', 'vspSurcharge', 'twSurcharge'],
    restrictFutureSaleDateContracts: false,
    showAnnouncementPopup: false,
    showCartSummary: true,
    showClaimDocumentDownload: false,
    showEconContractNumber: false,
    showMarkup: true,
    showOktaLoginButton: true,
    showRegisteredContractsControls: true,
    showSaveQuoteOnCartSummaryStep: true,
    showSaveQuoteOnCreateContractStep: false,
    showTermInQuotePdf: true,
    supportAncillaryFlow: true,
    supportCaptcha: true,
    supportCarfaxDecoding: false,
    supportCartValidation: true,
    supportChargerFlow: false,
    supportClassicVinDecodeApi: false,
    supportCoBorrower: true,
    supportContractRemittance: true,
    supportContractSearch: false,
    supportCreditInsuranceFlow: true,
    supportCustomerFormAPR: false,
    supportCustomerTaxExempt: false,
    supportDealerStatementsUrlOverride: false,
    supportDealInfoFormAPR: false,
    supportDOB: true,
    supportDot: false,
    supportGrossCapCost: true,
    supportInServiceDate: true,
    supportInspectionReports: false,
    supportManualVinDecoding: true,
    supportMsrp: true,
    supportMultilangContactPrint: false,
    supportMultiLanguage: false,
    supportNegativeEquity: false,
    supportPPMClaimsPortal: false,
    supportProvidedLienholder: false,
    supportProvince: true,
    supportTheftProtection: true,
    supportZipManualDecode: true,
    suppressDealerCost: false,
    transactionTypes: [FinanceType.Cash, FinanceType.Finance, FinanceType.Lease, FinanceType.Balloon],
    useBACID: false,
    useMarkup: true,
    useNewRatesApi: false,
    useNewSaveContractApi: false,
    useQuoteOnlyEndpoint: false,
    userCreationMode: 'CREATION_DISABLED',
    vehicleConditions: [VehicleConditionType.NEW, VehicleConditionType.CPO, VehicleConditionType.USED],
    vehiclePurchasePriceIsRequired: true,
    zeroCustomerCostForNonRegRates: false,
    addedToCartCostEditableInProductsView: true,
    customerCostGreaterThanDealerCostValidataionEnabled: true,
  },
  shoppingFlow: {
    steps: [
      {
        title: 'shoppingStepper.steps.dealInfo.title',
        url: ShoppingStepsUrls.DealInformation,
        paths: [
          {
            name: ShoppingFlowType.Ancillary,
            steps: [
              {
                title: 'shoppingStepper.steps.productSelection.title',
                url: ShoppingStepsUrls.AncillaryProductSelection,
              },
              {
                title: 'shoppingStepper.steps.cartSummary.title',
                url: ShoppingStepsUrls.CartSummary,
              },
            ],
          },
          {
            name: ShoppingFlowType.Insurance,
            steps: [],
          },
        ],
        currentPath: 'ANCILLARY',
      },
      {
        title: 'shoppingStepper.steps.creditInsurance.title',
        url: ShoppingStepsUrls.CreditorProductSelection,
      },
      {
        title: 'shoppingStepper.steps.customerInfo.title',
        url: ShoppingStepsUrls.CustomerInformation,
      },
      {
        title: 'shoppingStepper.steps.createContract.title',
        url: ShoppingStepsUrls.CreateContract,
      },
    ],
  },
  historyTabReadOnlyRoles: [Role.CorporateSales],
  registerTabReadOnlyRoles: [Role.CorporateSales],
  shoppingFlowReadOnlyRoles: [Role.CorporateSales],
  showTooltipsForSaleDate: false,
  showTooltipsForInServiceDate: false,
  redirectUrls: [],
  invoiceBaseUrl: '',
  recaptchaSiteKey: '6LdwwasUAAAAAIuGozE0DtbOObT3eP3JTZRWhBo_',
  verbiage: {
    dashboardPage: {
      linkCards: {
        shopNow: 'dashboardPage.linkCards.shopNow',
        confirmContracts: 'dashboardPage.linkCards.confirmContracts',
      },
    },
    shoppingFlow: {
      createContractStep: {
        saveAncillaryButton: 'shoppingPage.checkout.grid.saveAncillaryButton',
      },
    },
    remitPage: {
      leftSideBar: {
        remitHeader: 'remitPage.leftSideBar.remitHeader',
      },
      pendingPage: {
        topFilter: {
          addAllToRemit: 'remitPage.pendingPage.topFilter.addAllToRemit',
        },
        grid: {
          addToRegister: 'remitPage.pendingPage.grid.addToRegister',
        },
        rightSideBar: {
          submitAll: 'remitPage.pendingPage.rightSideBar.submitAll',
        },
      },
      print: {
        register: 'remitPage.print.register',
        registered: 'remitPage.print.registered',
      },
    },
    contractOperationRecord: {
      registered: 'contractOperationRecord.registered',
    },
    tooltips: {
      unitMsrp: {
        message: 'tooltips.unitMsrp.message',
      },
    },
  },
};
